import React, { useRef } from "react";
import "./WhyChooseUs.css";
import { motion, useScroll, useTransform } from "framer-motion";
import { handleRegisterClick } from "./Common";
import { FaChalkboardTeacher, FaBriefcase, FaProjectDiagram, FaGlobe, FaCertificate } from "react-icons/fa";

const features = [
  {
    icon: <FaChalkboardTeacher />,
    title: "Industry-Led Curriculum",
    description: "Learn from real-world practitioners with case studies, live projects, and the latest industry tools.",
  },
  {
    icon: <FaBriefcase />,
    title: "Career Acceleration",
    description: "Personalized mentorship, resume-building, and guaranteed interview opportunities with top recruiters.",
  },
  {
    icon: <FaProjectDiagram />,
    title: "Hands-On Learning",
    description: "Work on live projects, collaborate with peers, and gain practical experience to stand out in the job market.",
  },
  // {
  //   icon: <FaGlobe />,
  //   title: "Global Networking",
  //   description: "Connect with AI leaders, alumni, and top hiring partners in our growing professional community.",
  // },
  {
    icon: <FaCertificate />,
    title: "Industry-Recognized Certification",
    description: "Earn certifications valued by employers worldwide, proving your expertise in AI and emerging technologies.",
  },
];

const WhyChooseUs = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.95]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0.8]);

  return (
    <div ref={ref} className="why-choose-us-container">
      {/* Background Video */}
      <video className="background-video" autoPlay loop muted playsInline webkit-playsinline>
        <source src="/training.mp4" type="video/mp4" />
      </video>

      {/* Dark Overlay */}
      <div className="video-overlay"></div>

      <motion.section
        className="why-choose-us"
        style={{ scale, opacity }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        <motion.h2 className="title">
          Why <span className="highlight">LilaQ.AI Academy?</span>
        </motion.h2>

        <motion.p className="subtitle">
        Elevate your career with a cutting-edge curriculum, immersive hands-on experience, and exclusive industry connections. Our programs are meticulously designed to bridge the gap between learning and real-world application, ensuring you gain the skills, confidence, and expertise needed to thrive in the competitive tech landscape.
        </motion.p>

        <motion.div className="features-grid">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card"
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: false, amount: 0.2 }}
              transition={{ delay: index * 0.15, duration: 0.5 }}
            >
              <div className="icon">{feature.icon}</div>
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.button
          className="cta-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleRegisterClick}
        >
          Enroll Now
        </motion.button>
      </motion.section>
    </div>
  );
};

export default WhyChooseUs;
