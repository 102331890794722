import React, { useState, useEffect } from "react";
import "./App.css";
import PlacementSection from "./PlacementSection";
import ExperienceSection from "./ExperienceSection";
import WhyChooseUs from "./WhyChooseUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion, useScroll, useTransform, AnimatePresence } from "framer-motion";
import FullStackTimeline from "./FullStackTimeline";
import UserDetailsForm from "./UserDetailsForm";
import { Fab, useScrollTrigger, Zoom, Tooltip, Tabs, Tab } from "@mui/material";
import { Modal, Backdrop, Fade, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FlutterDevelopment from "./FlutterDevelopment";
import AIMachineLearning from "./AIMachineLearning";
import { Link, Element, scroller } from "react-scroll";
import { Box } from "@mui/material";
import { handleRegisterClick } from "./Common";
import TypewriterEffect from "./TypewriterEffect";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from '@mui/icons-material/Call'
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CourseDetails from "./CourseDetails";
import CoursesPage from "./CoursesPage";
import PageLoadPopUp from "./PageLoadPopUp";
import HeroSlider from "./HeroSlider";
import NavigationBar from "./NavigationBar";


function BackToTop() {
  const trigger = useScrollTrigger();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Zoom in={trigger}>
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}  sx={{
          position: "fixed",
          bottom: { xs: 80, sm: 16 }, // Adjusted for mobile to avoid overlap with other buttons
          right: { xs: 16, sm: 16 },
          zIndex: 1000,
        }}
      >
        <Fab color="primary" size="medium" onClick={handleClick} aria-label="back to top"
        sx={{
          width: { xs: 40, sm: 48 },
          height: { xs: 40, sm: 48 },
        }}>
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
}


function App() {
  const [isSticky, setIsSticky] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.hero-section');
      if (heroSection) {
        const heroBottom = heroSection.getBoundingClientRect().bottom;
        setIsSticky(heroBottom <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = "/brochure.pdf";
    link.download = "LilaQAi_Academy_Brochure.pdf";
    link.click();
  };

  const { scrollY } = useScroll();

  const titleLogo = useTransform(scrollY, [0, 200], [0, -100]);
  const titleY = useTransform(scrollY, [0, 300], [0, -100]);
  const titleScale = useTransform(scrollY, [0, 300], [1, 1]);
  const titleOpacity = useTransform(scrollY, [0, 300], [1, 0]);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleClosePopup = () => {
    setIsFormVisible(false);
  };

  const HomePage = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false); // Show modal on load

    const handleHomeClosePopup = () => {
      setIsPopupVisible(false);
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsPopupVisible(true);
      }, 1500);
      return () => clearTimeout(timer); // Cleanup
    }, []);

    return (
      <div className="app">
        <NavigationBar isSticky={isSticky} />
        <Modal
          open={isPopupVisible}
          onClose={handleHomeClosePopup}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={isPopupVisible}>
            <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: "80%", md: 600 },
              maxWidth: "95vw",
              maxHeight: { xs: "90vh", sm: "80vh" },
              overflowY: "auto",
              p: { xs: 2, sm: 3 },
              borderRadius: 4,
              background: "linear-gradient(135deg, #4a2b7d, #2d1b4d)",
              boxShadow: "0px 10px 30px rgba(255, 223, 0, 0.3)",
              color: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              outline: "none",
            }}
            >
              {/* Close Button */}
              <IconButton
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  color: "white",
                }}
                onClick={handleHomeClosePopup}
              >
                <CloseIcon />
              </IconButton>

              {/* Discount Heading */}
              <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                color: "#d4af37",
                textAlign: "center",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                textShadow: "0 0 15px rgba(255, 223, 0, 0.8)",
                animation: "glow 0.5s infinite alternate ease-in-out",
                "@keyframes glow": {
                  "0%": { textShadow: "0 0 10px rgba(255, 223, 0, 0.5)" },
                  "100%": { textShadow: "0 0 25px rgba(255, 223, 0, 1)" },
                },
              }}
              >
                🚀 LiLaQ.AI Placement Assistance Drives are Now Live! 🎯
              </Typography>

              {/* Subtitle */}
              <Typography
                variant="h6"
                sx={{
                  mt: 1,
                  mb: 2,
                  color: "#fff",
                  fontWeight: "500",
                  textAlign: "center",
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                }}
              >
                Limited Time Offers and Discounts on all Courses
              </Typography>

              {/* Discount Image */}
              <Typography sx={{
                'fontWeight': "800",
                // textTransform: 'uppercase', 
                fontSize: { xs: 24, sm: 30 },
              }}>Get Upto</Typography>
              <img
                src="/discount.png" // Replace with your actual discount image
                alt="Discount"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  height: "auto",
                  objectFit: "contain",
                  borderRadius: "8px",
                  margin: "1rem 0",
                }}
              />

              {/* CTA Button */}
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                  fontWeight: "bold",
                  background: "#d4af37",
                  color: "#2d1b4d",
                  padding: { xs: "8px 20px", sm: "12px 28px" },
                  borderRadius: "30px",
                  boxShadow: "0px 4px 12px rgba(255, 223, 0, 0.5)",
                  "&:hover": {
                    background: "#c19b2e",
                    boxShadow: "0px 6px 20px rgba(255, 223, 0, 0.7)",
                  },
                }}
                onClick={() => {
                  // Redirect to the enrollment section or page
                  // const enrollSection = document.getElementById("timeline");
                  // if (enrollSection) {
                  //   enrollSection.scrollIntoView({ behavior: "smooth" });
                  // }
                  handleRegisterClick()
                }}
              >
                Enquire Now
              </Button>
            </Box>
          </Fade>
        </Modal>

        <header className="hero-section">
          <video className="hero-video" autoPlay loop muted playsInline webkit-playsinline>
            <source src="/training.mp4" type="video/mp4" />
          </video>




          <div className="hero-overlay" >
            <HeroSlider />
          </div>

          {/* Rest of the component remains unchanged */}
          {/* Fab buttons */}
          <Fab
            style={{
              position: 'fixed',
              top: { xs: '85%', sm: '89%' },
              right: { xs: '10px', sm: '15px' },
              transform: 'translateY(-50%)',
              background: "rgba(255, 255, 255, 0.15)",
              color: "#4c6ef5",
              width: 'auto',
              height: { xs: 40, sm: 50 },
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              zIndex: 10,
              transition: "all 0.3s ease",
              padding: '0 15px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
            onClick={handleDownloadBrochure}
            aria-label="Download Brochure"
          >
            <DownloadIcon style={{ fontSize: { xs: 20, sm: 24 }, color: "#ffffff" }} />
            <span style={{
              color: '#ffffff',
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
              fontWeight: 500,
              fontFamily: 'Poppins, sans-serif',
              textTransform: 'capitalize',
            }}>
              Brochure
            </span>
          </Fab>

          {/* Keep existing WhatsApp and Call buttons */}
          <Box sx={{
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            bottom: { xs: 16, sm: 22 },
          left: { xs: 16, sm: 24, md: 35 },
            zIndex: 1000,
            transition: 'left 0.3s ease'
          }}>
            <Fab
              style={{
                backgroundColor: "#b39be7",
                color: "#ffffff",
                width: { xs: 48, sm: 56 },
                height: { xs: 48, sm: 56 },
                borderRadius: "50%",
              }}
              size="small"
              href="tel:+918714258111"
              target="_blank"
              aria-label="Phone"
            >
              <CallIcon style={{  fontSize: { xs: 22, sm: 26 } }}  />
            </Fab>
            <Fab
              style={{
                backgroundColor: "#25D366",
                color: "#ffffff",
                width: { xs: 48, sm: 56 },
                height: { xs: 48, sm: 56 },
                borderRadius: "50%"
              }}
              size="large"
              href="https://wa.me/+918714258111"
              target="_blank"
              aria-label="WhatsApp"
            >
              <WhatsAppIcon style={{ fontSize: { xs: 28, sm: 32 } }} />
            </Fab>
          </Box>

          {/* Modal remains unchanged */}
          <Modal
            open={isFormVisible}
            onClose={handleClosePopup}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={isFormVisible}>
              <div className="modal-content">
                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                  <IconButton
                    aria-label="close"
                    className="close-button"
                    onClick={handleClosePopup}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <UserDetailsForm />
              </div>
            </Fade>
          </Modal>
        </header>
        {/* Navigation Links */}
        <nav className="navigation">
          <Link to="timeline" smooth={true} duration={500}>
          </Link>
        </nav>

        {/* <PageLoadPopUp /> */}
        <Element name="why-choose-us" id="why-choose-us">
          <WhyChooseUs />
        </Element>


        {/* Experience Section */}
        {/* <Element name="experience">
        <ExperienceSection />
      </Element> */}

        {/* Full Stack Timeline Section */}
        <Element name="timeline" id="timeline">
          <div className="timeline-section">
            {/* <FullStackTimeline /> */}
            {/* <FlutterDevelopment /> */}
            {/* <AIMachineLearning /> */}
          </div>
        </Element>

        <Element name="timeline" id="timeline">
          <div className="timeline-section">
            <CoursesPage />

          </div>
        </Element>
        {/* <Router>
          <Routes>
            <Route path="/" element={<CoursesPage />} />
            <Route path="/courses/:courseId" element={<CourseDetails />} />
          </Routes>
        </Router> */}

        <Element name="placement">
          <PlacementSection />
        </Element>

        <BackToTop />

        {/* Footer */}
        {/* <footer className="footer">
        <p>© 2024 LilaQ.Ai Academy | All rights reserved.</p>
      </footer> */}

        {/* Footer */}
        <footer className="footer">
          <div className="footer-container">
            <div className="footer-section about">
              <div style={{'display': 'flex', 'flexDirection': 'row', justifyContent:'left', gap: 10}}>
                <img src="/iso-certif.png" alt="ISO 29993 Certified" className="certificate-logo" style={{ width: '60px', height: 'auto' }} />
                <img src="/iso-9001-certif-blue.png" alt="ISO 9001 Certified" className="certificate-logo" style={{ width: '60px', height: 'auto' }} />
              </div>

              <h3>About LilaQ.Ai Academy</h3>
              <p>
                LilaQ.Ai Academy empowers future leaders through innovative training in Generative AI,
                Data Science & AI/ML, and Cloud Development.
              </p>
              <div className="social-icons">
                <a href="https://www.facebook.com/lilaqai/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.linkedin.com/company/lilaqaiacademy" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href=" https://www.instagram.com/lilaqai" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/@lilaqaiacademy" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://x.com/lilaqaiacademy?s=11" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
              </div>
            </div>
            <div className="footer-section links">
              <h3>Quick Links</h3>
              <ul>
                <li><a href="#timeline">Courses</a></li>
                <li><a href="#why-choose-us">About Us</a></li>
                <li><a href="#contact">Contact Us</a></li>
              </ul>
            </div>
            <div className="footer-section contact">
              <h3>Contact</h3>
              <p><i className="fas fa-map-marker-alt"></i> Near Info Park, Kakkanad, Kochi, Kerala - 682042</p>
              <p><i className="fas fa-phone"></i> <a href='tel:+91 8714258111'>+91 8714258111</a></p>
              <p><i className="fas fa-envelope"></i> <a href='mailto:info@lilaq.ai'>info@lilaq.ai</a></p>
            </div>
          </div>
          <p className="footer-copyright">© 2024 LilaQ.Ai Academy | All rights reserved.</p>
        </footer>
      </div>
    )
  }

  return (
    <Router>
      <Routes>
        {/* Homepage - Only renders HomePage component */}
        <Route path="/" element={<HomePage />} />

        {/* Courses Page - Navigates to Courses without Homepage */}
        <Route path="/courses" element={<CoursesPage />} />

        {/* Course Details - Opens Full Page without Homepage */}
        <Route path="/courses/:courseId" element={<CourseDetails />} />
      </Routes>
    </Router>
  );
}

export default App;

