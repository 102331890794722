import React from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box, Typography, Button, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./CourseDetails.css";
import { ExpandMore } from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import NavigationBar from "./NavigationBar";
import { handleRegisterClick } from "./Common";

const courseData = {
  "full-stack-development": {
    title: "Full-Stack Development",
    image: "/full-stack.png",
    duration: "6 Months",
    mode: "Online & Offline",
    overview: "The Full Stack Development course at LilaQ.AI Academy is designed to equip learners with the skills required to build modern, scalable web applications. This hands-on program covers both front-end and back-end development, databases, deployment strategies, and best practices in software engineering. By the end of the course, students will have built multiple projects and a capstone portfolio, making them job-ready.",
    keyTopics: [
      {
        "module": "Module 1: Introduction to Full Stack Development",
        "details": "-   Overview of web development\n-   Understanding front-end, back-end, and databases\n-   Setting up the development environment"
      },
      {
        "module": "Module 2: Front-End Development",
        "details": "#### HTML, CSS, and JavaScript\n\n-   Responsive design principles\n-   CSS frameworks (Bootstrap, Tailwind CSS)\n\n#### JavaScript & Modern Frameworks\n\n-   ES6+ features\n-   React.js fundamentals (components, hooks, state management)\n-   Advanced React (Redux, Context API, Next.js basics)"
      },
      {
        "module": "Module 3: Back-End Development",
        "details": "#### Node.js & Express.js\n\n-   REST API development\n-   Authentication & Authorization (JWT, OAuth)\n\n#### Database Management\n\n-   SQL (PostgreSQL/MySQL) vs NoSQL (MongoDB)\n-   ORM (Prisma, Sequelize, Mongoose)\n-   Database relationships and optimization"
      },
      {
        "module": "Module 4: Full Stack Integration",
        "details": "-   Connecting front-end with back-end\n-   API consumption and state management\n-   WebSockets and real-time applications"
      },
      {
        "module": "Module 5: DevOps & Deployment",
        "details": "-   Version control with Git & GitHub\n-   CI/CD pipelines\n-   Cloud deployment (Vercel, Netlify, AWS, DigitalOcean)\n-   Docker & containerization basics"
      },
      {
        "module": "Module 6: Advanced Topics",
        "details": "-   Authentication & security best practices\n-   Serverless computing & microservices\n-   Testing (Unit, Integration, End-to-End)\n-   Performance optimization & scalability"
      },
      {
        "module": "Module 7: Capstone Project & Career Readiness",
        "details": "-   End-to-end project development\n-   Resume building, interview prep, and GitHub portfolio\n-   Networking and placement assistance"
      },
    ],
    skillsCovered: [
      "React, Angular",
      "Node.js, Express",
      "MongoDB, PostgreSQL",
      "Docker & Docker Compose",
      "Cloud & DevOps",
    ],
    targetAudience: "Graduates & Tech enthusiasts looking to enter the field of Full-Stack Development.",
    // certification: {
    //   title: "Certified Full-Stack Developer",
    //   logo: "/certification-logo.png",
    // },
  },
  "ai-machine-learning": {
    title: "AI & Machine Learning",
    image: "/ai-ml.webp",
    duration: "4 Months",
    mode: "Online & Offline",
    overview: "The AI & Machine Learning course at LilaQ.AI Academy is designed to equip learners with essential skills in artificial intelligence, deep learning, and machine learning model development. This hands-on program covers machine learning algorithms, neural networks, natural language processing (NLP), AI deployment, and ethical considerations. By the end of the course, students will have built multiple AI models and a capstone project, making them industry-ready.",
    keyTopics: [
      {
        "module": "Module 1: Machine Learning Fundamentals",
        "details": "-   Introduction to AI & ML\n-   Supervised vs. Unsupervised Learning\n-   Regression & Classification Algorithms\n-   Feature Engineering & Model Evaluation"
      },
      {
        "module": "Module 2: Deep Learning & Neural Networks",
        "details": "-   Introduction to Neural Networks\n-   Feedforward & Convolutional Neural Networks (CNNs)\n-   Recurrent Neural Networks (RNNs) & LSTMs\n-   Hands-on with TensorFlow & PyTorch"
      },
      {
        "module": "Module 3: Natural Language Processing (NLP)",
        "details": "-   Tokenization, Word Embeddings & Transformers\n-   Sentiment Analysis & Text Classification\n-   Chatbots & AI-Powered Assistants\n-   Hugging Face & Large Language Models (LLMs)"
      },
      {
        "module": "Module 4: AI Deployment & Ethics",
        "details": "-   Model Optimization & Performance Tuning\n-   Cloud AI Deployment (AWS, GCP, Azure)\n-   AI Ethics, Bias, and Responsible AI\n-   Final Capstone Project & Portfolio Building"
      }
    ],
    skillsCovered: [
      "Python, TensorFlow, PyTorch",
      "Computer Vision, NLP, Speech to Text Processing",
      "AI Model Deployment",
      "Data Science Best Practices",
    ],
    targetAudience: "Graduates and professionals interested in AI & ML, Data analysts and software engineers looking to upskill and individuals with basic programming knowledge seeking AI expertise",
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },

  },
  "genai-prompt-engg": {
    title: "Generative AI and Prompt Engineering",
    image: "/genai.webp",
    duration: "4 Months",
    mode: "Online & Offline",
    overview: "The Generative AI & Prompt Engineering course at LilaQ.AI Academy is designed to equip learners with cutting-edge skills in AI-driven content creation, advanced prompt engineering, and agentic workflows. This hands-on program covers large language models (LLMs), fine-tuning AI models, Retrieval-Augmented Generation (RAG), and AI-powered automation. By the end of the course, students will have built custom AI agents, interactive chatbots, and real-world AI applications.",
    keyTopics: [
      {
        "module": "Module 1: Foundations of Generative AI",
        "details": "-   Introduction to Large Language Models (LLMs)\n-   Evolution of AI-generated content\n-   Understanding AI Transformers & Diffusion Models\n-   Applications of Generative AI in various industries\n-   Ethics, Bias, and Responsible AI Usage"
      },
      {
        "module": "Module 2: Mastering Prompt Engineering",
        "details": "-   Basics of Prompt Engineering\n-   Optimizing prompts for different AI models\n-   Advanced prompting techniques (Chain-of-Thought, Self-Consistency, Few-Shot Learning)\n-   Hands-on with **OpenAI’s GPT, Claude, Gemini, and more**"
      },
      {
        "module": "Module 3: Advanced AI Model Fine-Tuning & Agentic Workflows",
        "details": "-   Fine-tuning pre-trained LLMs for custom use cases\n-   **Agentic Workflows** – AI-driven automation with **reasoning & planning**\n-   **Building autonomous AI agents** for complex tasks\n-   **Retrieval-Augmented Generation (RAG)** for smarter, fact-based responses\n-   Developing AI-powered chatbots, copilots, and virtual assistants"
      },
      {
        "module": "Module 4: AI Automation, Deployment & Real-World Integration",
        "details": "-   Using APIs & AI SDKs for app development\n-   Automating workflows with AI agents (LangChain, AutoGPT, BabyAGI)\n-   Cloud AI Deployment (**AWS, GCP, Azure**)\n-   AI in Business & Productivity Tools (Zapier, Make, Notion AI, etc.)\n-   Final Capstone Project: End-to-End AI Application Development"
      }
    ],
    skillsCovered: [
      "**Generative AI & Large Language Models (LLMs)** – GPT, Claude, Gemini, Llama, Mistral",
      "Hugging Face, OpenAI Playground, Pinecone",
      "**Prompt Engineering** – Few-shot, Chain-of-Thought, Self-Consistency, Multi-Turn Prompting",
      "**Fine-Tuning AI Models** – Customizing LLMs for domain-specific applications",
      "**Retrieval-Augmented Generation (RAG)** – Enhancing AI accuracy with real-time knowledge retrieval",
      "**Cloud AI Deployment** – AWS, GCP, Azure for scalable AI applications"
    ],
    targetAudience: "Developers, data scientists, and AI enthusiasts, content creators and marketers looking to enhance AI-driven creativity and Professionals aiming to leverage AI tools for automation and productivity",
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  "big-data-analytics": {
    title: "Big Data Analytics",
    image: "/bigdata.jpg",
    duration: "4 Months",
    mode: "Online & Offline",
    overview: "The Big Data Analytics course at LilaQ.AI Academy is designed to equip learners with the skills to process, analyze, and derive insights from massive datasets using modern big data technologies. This hands-on program covers data engineering, distributed computing, data visualization, machine learning for big data, and real-time data processing. By the end of the course, students will be proficient in handling large-scale data and building data-driven solutions for business and industry applications.",
    keyTopics: [
      {
        "module": "Module 1: Foundations of Big Data & Data Engineering",
        "details": "-   Introduction to Big Data & Data Analytics\n-   Big Data Ecosystem – Hadoop, Spark, Kafka, Flink\n-   Structured vs. Unstructured Data – Data lakes, warehouses, and lakehouses\n-   Data Preprocessing, Cleaning & Transformation\n-   ETL (Extract, Transform, Load) Pipelines"
      },
      {
        "module": "Module 2: Distributed Computing & Scalable Data Processing",
        "details": "-   Apache Hadoop & HDFS (Hadoop Distributed File System)\n-   MapReduce & Parallel Processing\n-   Apache Spark & PySpark – In-memory distributed data processing\n-   Batch vs. Real-time Data Processing – Spark Streaming, Flink, and Kafka Streams\n-   SQL & NoSQL Databases – Cassandra, MongoDB, and BigQuery"
      },
      {
        "module": "Module 3: Data Analytics, Machine Learning & Visualization",
        "details": "-   Exploratory Data Analysis (EDA) & Feature Engineering\n-   Machine Learning for Big Data – Scalable ML with Spark MLlib\n-   Predictive & Prescriptive Analytics\n-   Data Visualization – Power BI, Tableau, Matplotlib, Seaborn\n-   Big Data in Business Intelligence (BI) & Decision Making"
      },
      {
        "module": "Module 4: Cloud, Security & Real-World Applications",
        "details": "Cloud-Based Big Data Solutions – AWS (Glue, Redshift), GCP (BigQuery, DataFlow), Azure (Synapse Analytics)\nData Governance, Privacy & Security – GDPR, Compliance, Data Encryption\nReal-Time Big Data Use Cases – Fraud Detection, Recommendation Systems, IoT Analytics\nFinal Capstone Project: End-to-End Big Data Pipeline & Analysis"
      }
    ],
    skillsCovered: [
      "Big Data Technologies – Hadoop, Spark, Kafka, Flink",
      "Data Engineering & ETL Pipelines – Airflow, Apache NiFi, SQL, NoSQL",
      "Distributed Computing – Parallel Processing with MapReduce & Spark",
      "Data Visualization & Business Intelligence (BI) – Power BI, Tableau, Seaborn",
      "Data Governance & Security – GDPR, Encryption, Compliance"
    ],
    targetAudience: "Data professionals, analysts, and engineers looking to upskill in big data technologies, business professionals interested in data-driven decision-making and developers aiming to work with scalable data solutions",
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  "corporate-trainings": {
    title: "Corporate Trainings",
    image: "/corp-train.avif",
    duration: "Varies based on complexity",
    mode: "Online, Offline, Hybrid (Live sessions, Recorded lectures, Self-paced modules)",
    overview: "A brief introduction to the training program, highlighting its objectives, learning outcomes, and how it aligns with business goals.",
    keyTopics: [
      {
        "module": "Introduction & Fundamentals",
        "details": "-   Overview of the training program and its objectives\n-   Understanding the relevance and importance of the subject matter\n-   Industry trends and best practices\n-   Introduction to key concepts, terminologies, and frameworks"
      },
      {
        "module": "Key Concepts & Frameworks",
        "details": "-   Deep dive into essential theories, methodologies, and models\n-   Real-world applications and case studies\n-   Industry-standard tools and technologies (if applicable)\n-   Practical examples to bridge the gap between theory and practice"
      },
      {
        "module": "Hands-on Practice & Case Studies",
        "details": "-   Interactive sessions, group exercises, and simulations\n-   Analyzing case studies from real-world business scenarios\n-   Solving challenges and implementing learned concepts\n-   Peer discussions and feedback loops"
      },
      {
        "module": "Real-World Applications & Business Integration",
        "details": "-   Applying the concepts to workplace scenarios\n-   Developing strategies for implementation in the corporate environment\n-   Addressing challenges and overcoming roadblocks\n-   Customizing learning for business-specific needs"
      },

    ],
    skillsCovered: [],
    targetAudience: "",
    group: true,
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  "safe-agile-training": {
    title: "SAFe® Agile Training",
    image: "/safe.png",
    duration: "NA",
    mode: "Offline",
    overview: "Learn Scaled Agile Framework (SAFe®), empowering professionals to implement Agile practices across the enterprise efficiently and effectively. LilaQ AI Academy’s SAFe® Agile Training enables organizations need to scale agility beyond teams to stay competitive and deliver value faster.",
    keyTopics: [
      {
        "module": "Introduction to SAFe & Agile Frameworks",
        "details": "-   Overview of **Agile, Lean, and Scaled Agile Framework (SAFe)**\n-   **SAFe Core Values & Principles** – Alignment, Transparency, Execution, Leadership\n-   **SAFe vs. Traditional Agile** – When to scale Agile for enterprises\n-   **Lean-Agile Mindset** – Leadership and cultural transformation"
      },
      {
        "module": "SAFe Roles & Responsibilities",
        "details": "-  SAFe Team Roles – Agile Teams, Product Owners, Scrum Masters\n- ART (Agile Release Train) & PI Planning – Organizing teams at scale\n- SAFe Program & Portfolio Levels – Strategic alignment & governance\n- Lean Portfolio Management (LPM) – Funding and value-driven execution"
      },
      {
        "module": "Agile Release Trains (ART) & PI Planning",
        "details": "- Building High-Performing Agile Teams\n- SAFe Events & Ceremonies – Iteration Planning, Daily Standups, System Demo\n- PI Planning Execution – Aligning teams for continuous delivery\n- DevOps & Continuous Delivery Pipeline in SAFe"
      },
      {
        "module": "SAFe Implementation & Value Stream",
        "details": "- Identifying & Optimizing Value Streams\n- Flow Metrics & KPIs in SAFe – Measuring success in scaled Agile\n- SAFe in Large Enterprises – Case studies & best practices\n- SAFe Certification Preparation & Exam Guidance"
      }
    ],
    skillsCovered: [],
    targetAudience: "",
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
    group: true,
  },
  "onsite-campus-training": {
    title: "On-Site Campus Training",
    image: "/campus.webp",
    duration: "Customizable (Based on Institution Requirements)",
    mode: "On-Campus (In-Person Training)",
    overview: "The On-Site Campus Training Program is designed to bridge the gap between academic learning and industry expectations. This program equips students with hands-on experience, industry-relevant skills, and real-world exposure to make them career-ready. Delivered by expert instructors, the training integrates technical, soft skills, and project-based learning, ensuring students gain practical knowledge that enhances their job readiness.",
    keyTopics: [
      {
        "module": "Industry & Technology Orientation",
        "details": "- Overview of the latest industry trends and emerging technologies\n- Real-world case studies and applications\n- Career pathways and job roles in various domains"
      },
      {
        "module": "Core Technical Skills Training (Customized based on requirement)",
        "details": "- Software Development (Full-Stack, Python, Java, C++)\n- Cloud Computing (AWS, Azure, GCP)\n- Data Science & AI (Machine Learning, NLP, Big Data) DevOps & Cybersecurity Best Practices"
      },
      {
        "module": "Hands-On Projects & Capstone Work",
        "details": "- Real-world projects aligned with industry expectations\n- Team-based problem-solving exercises\n- Live coding sessions & hackathons"
      },
      {
        "module": "Soft Skills & Professional Development",
        "details": "- Communication, Presentation & Leadership Skills\n- Resume Building & Personal Branding\n- Mock Interviews & Technical Assessments"
      },
      {
        "module": "Placement Assistance & Career Readiness",
        "details": "- 1-on-1 Career Mentorship Sessions\n- Interaction with Industry Experts & Alumni\n- Job Referral & Internship Support"
      }
    ],
    skillsCovered: [
      "Technical & Industry-Specific Skills",
      "Project-Based Learning & Hands-On Experience",
      "Communication & Interview Readiness",
      " Problem-Solving & Critical Thinking",
      "Professionalism & Workplace Etiquette"
    ],
    targetAudience: "",
    group: true
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  "cloud-computing": {
    title: "Mastering Cloud Resources ( AWS & Azure )",
    image: "/cloud.jpg",
    duration: "4 months",
    mode: "Online & Offline",
    overview: "This course is designed to equip learners with a deep understanding of cloud infrastructure on AWS and Azure. Participants will gain hands-on experience in provisioning, managing, and optimizing cloud resources while ensuring security, scalability, and cost efficiency. By the end of the course, students will have built multiple cloud projects and a capstone project to showcase their expertise.",
    keyTopics: [
      {
        "module": "Module 1: Introduction to Cloud Computing & Architecture",
        "details": "- Cloud computing models (IaaS, PaaS, SaaS)  \n- Public vs. Private vs. Hybrid Cloud  \n- Cloud architecture fundamentals  \n- Multi-cloud & hybrid cloud strategies"
      },
      {
        "module": "Module 2: AWS Cloud Infrastructure & Services",
        "details": "- AWS Global Infrastructure (Regions, Availability Zones)\n- Virtual Private Cloud (VPC), EC2, S3, IAM\n- AWS Elastic Load Balancing (ELB) & Auto Scaling\n- AWS Lambda & Serverless Computing\n- AWS Security & Compliance Best Practices🔹"
      },
      {
        "module": "Module 3: Azure Cloud Infrastructure & Services",
        "details": "- Azure Resource Manager (ARM) & Subscription Model\n- Virtual Machines, Storage, Networking in Azure\n- Azure Functions & App Services\n- Azure Kubernetes Service (AKS)\n- Azure Security & Governance"
      },
      {
        "module": "Module 4: Cloud Identity, Security & Networking",
        "details": "- Identity and Access Management (IAM) in AWS & Azure\n- Firewalls, VPNs & Security Groups\n- Data encryption & compliance in the cloud\n- Cloud networking (AWS VPC Peering, Azure VNets)"
      },
      {
        "module": "Module 5: Cloud Cost Optimization & Performance Monitoring",
        "details": "- AWS & Azure Pricing Models\n- Budgeting & Cost Monitoring Tools (AWS Cost Explorer, Azure Cost Management)\n- Performance monitoring with AWS CloudWatch & Azure Monitor\n- Best practices for optimizing cloud costs"
      },
      {
        "module": "Module 6: DevOps & Automation in the Cloud",
        "details": "- Infrastructure as Code (Terraform, CloudFormation, ARM Templates)\n- CI/CD Pipelines with AWS CodePipeline & Azure DevOps\n- Monitoring & Logging (AWS CloudTrail, Azure Log Analytics)\n- Cloud-native application deployment"
      }
    ],
    skillsCovered: [],
    targetAudience: "",
    group: true
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  "ui-ux-design": {
    title: "Mastering UI/UX Design",
    image: "/ui-ux.jpeg",
    duration: "4 months",
    mode: "Online & Offline",
    overview: "The Mastering UI/UX Design course at LilaQ.AI Academy is designed to provide in-depth knowledge of user interface (UI) and user experience (UX) design. This course covers design thinking, wireframing, prototyping, usability testing, and industry tools like Figma, Adobe XD, and Sketch. Learners will build real-world projects and develop a professional portfolio to prepare for careers as UI/UX Designers.",
    keyTopics: [
      {
        "module": "Module 1: Fundamentals of UI/UX Design",
        "details": "- Understanding UI vs. UX: Key Differences & Industry Insights\n- Design Thinking & User-Centered Design Principles\n- Accessibility & Inclusivity in Design"
      },
      {
        "module": "Module 2: User Research & UX Strategy",
        "details": "- Conducting User Research & Competitive Analysis\n- Creating User Personas & User Journeys\n- UX Psychology & Behavioral Design"
      },
      {
        "module": "Module 3: Wireframing & Prototyping",
        "details": "- Low-Fidelity vs. High-Fidelity Wireframes\n- Interactive Prototyping with Figma & Adobe XD\n- A/B Testing & Iterative Design"
      },
      {
        "module": "Module 4: UI Design Principles & Visual Aesthetics",
        "details": "- Color Theory, Typography, & Layout Grids\n- Design Systems & Component Libraries\n- Creating Responsive & Mobile-First UI"
      },
      {
        "module": "Module 5: Usability Testing & Design Optimization",
        "details": "- Conducting Usability Testing & Heuristic Evaluation\n- Heatmaps, Eye-Tracking & User Behavior Analysis\n- Iterating Design Based on User Feedback"
      },
      {
        "module": "Module 6: Building a Professional UI/UX Portfolio",
        "details": "- Creating Case Studies & Presenting Work\n- Working with Developers: Handoff & Collaboration\n- UI/UX Design Interview Prep & Freelancing Tips"
      }
    ],
    skillsCovered: [
      "UI/UX Design Fundamentals",
      "Design Thinking & User Research",
      "Wireframing & Prototyping (Figma, Adobe XD, Sketch)"
    ],
    targetAudience: "Aspiring UI/UX Designers looking to enter the field, Graphic Designers transitioning into UX/UI roles, Product Managers & Developers who want to understand UX principles",
    group: true
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  "flutter-dev": {
    title: "Cross Platform App Development using Flutter",
    image: "/flutter.jpg",
    duration: "4 months",
    mode: "Online & Offline",
    overview: "The Cross-Platform App Development using Flutter course at LilaQ.AI Academy equips learners with the skills to build high-performance, natively compiled applications for iOS, Android, Web, and Desktop using a single codebase. This hands-on program covers Flutter fundamentals, Dart programming, UI/UX design, API integration, state management, and deployment. By the end of the course, students will have built multiple real-world projects and a capstone app.",
    keyTopics: [
      {
        "module": "Module 1: Introduction to Flutter & Dart",
        "details": "- Overview of Cross-Platform Development\n- Setting Up Flutter & Dart Environment\n- Dart Programming Basics (Variables, Functions, Classes)\n- Understanding Flutter Widget Tree & UI Components"
      },
      {
        "module": "Module 2: Building Responsive User Interfaces",
        "details": "- Flutter Widgets: Stateless vs. Stateful\n- Material Design & Cupertino Widgets\n- Working with Navigation & Routing\n- UI/UX Best Practices for Cross-Platform Apps"
      },
      {
        "module": "Module 3: State Management & Advanced Flutter Concepts",
        "details": "- State Management Techniques (Provider, Riverpod, Bloc)\n- Working with Forms, Validation & User Input\n- Handling Errors & Debugging Flutter Apps"
      },
      {
        "module": "Module 4: Backend Integration & API Handling",
        "details": "- Fetching Data from REST APIs & GraphQL\n- Local Storage (Shared Preferences, Hive, SQLite)\n- Firebase Authentication & Firestore Database"
      },
      {
        "module": "Module 5: Performance Optimization & Native Features",
        "details": "- Animations & Gesture Handling\n- Device Permissions (Camera, Location, Notifications)\n- Working with Native Code (Platform Channels)"
      },
      {
        "module": "Module 6: Testing, Deployment & Publishing Apps",
        "details": "- Writing Unit & Integration Tests\n- Building & Deploying Flutter Apps (Android & iOS)\n- Publishing Apps on Play Store & App Store\n- Continuous Integration & Deployment (CI/CD)"
      },
      {
        "module": "Module 7: Capstone Project & Portfolio Development",
        "details": "- Building a Complete Cross-Platform App\n- UI/UX Enhancements & Feature Improvements\n- Preparing for Flutter Developer Interviews\n- Showcasing Your Portfolio on GitHub & App Stores"
      },

      

    ],
    skillsCovered: [
      "Flutter & Dart Programming",
      "UI/UX Design with Flutter Widgets",
      "API Integration & Database Handling",
      "Firebase Authentication & Cloud Firestore"
    ],
    targetAudience: "Aspiring Mobile App Developers who want to build cross-platform apps, Students & Professionals interested in Flutter & Dart, Web Developers looking to transition into mobile development",
    group: true
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  "dev-ops": {
    title: "Mastering DevOps & Cloud Automation",
    image: "/dev-ops.png",
    duration: "4 months",
    mode: "Online & Offline",
    overview: "The Mastering DevOps & Cloud Automation course at LilaQ.AI Academy provides hands-on training in CI/CD, cloud infrastructure automation, containerization, monitoring, and security. This course covers modern DevOps tools and methodologies, preparing learners to optimize software development lifecycles, automate deployments, and ensure seamless scalability of cloud applications.",
    keyTopics: [
      {
        "module": "Module 1: Introduction to DevOps & Agile Methodology",
        "details": "- DevOps Principles & Benefits\n- Agile, Scrum & Kanban in DevOps\n- DevOps Culture & Collaboration Tools"
      },
      {
        "module": "Module 2: Linux & Scripting for Automation",
        "details": "- Linux Fundamentals & Command Line Operations\n- Shell Scripting for Automation\n- Managing System Processes & Services"
      },
      {
        "module": "Module 3: Version Control with Git & GitHub",
        "details": "- Git Basics: Branching, Merging & Rebase\n- GitHub Actions & Workflows\n- GitOps for Infrastructure as Code"
      },
      {
        "module": "Module 4: Continuous Integration & Continuous Deployment (CI/CD)",
        "details": "- Understanding CI/CD Pipelines\n- Implementing CI/CD using Jenkins & GitHub Actions\n- Automating Build, Test & Deployment Workflows"
      },
      {
        "module": "Module 5: Infrastructure as Code (IaC) & Cloud Automation",
        "details": "- Introduction to Terraform & Ansible\n- Automating Cloud Infrastructure (AWS, Azure, GCP)\n- Configuration Management with Ansible"
      },
      {
        "module": "Module 6: Containerization with Docker & Kubernetes",
        "details": "- Docker Fundamentals: Images, Containers & Volumes\n- Kubernetes Architecture & Cluster Management\n- Deploying Scalable Applications with Kubernetes"
      },
      {
        "module": "Module 7: Monitoring, Logging & Security in DevOps",
        "details": "- Prometheus & Grafana for Monitoring\n- ELK Stack for Log Management\n- DevSecOps: Security in CI/CD Pipelines"
      },
      {
        "module": "Module 8: Cloud Deployment & Cost Optimization",
        "details": "- Prometheus & Grafana for Monitoring\n- ELK Stack for Log Management\n- DevSecOps: Security in CI/CD Pipelines"
      },
      {
        "module": "Module 9: Capstone Project & Career Preparation",
        "details": "- Hands-on DevOps Project with Real-World Use Case\n- Resume Building & Interview Prep\n- Best Practices for DevOps Engineers"
      },

      

    ],
    skillsCovered: [
      "DevOps Lifecycle & Agile Practices",
      "CI/CD with Jenkins & GitHub Actions",
      "Infrastructure as Code (Terraform, Ansible)",
      "Docker & Kubernetes for Containerization",
      "ELK Stack, Grafana and Prometheus",
    ],
    targetAudience: "Software Developers looking to implement DevOps practices System Administrators & IT Engineers transitioning to DevOps roles, Cloud Engineers interested in automation & CI/CD, ",
    group: true
    // certification: {
    //   title: "AI & ML Certification",
    //   logo: "/ai-certification.png",
    // },
  },
  

};

const CourseDetails = () => {
  const { courseId } = useParams();
  const course = courseData[courseId];
  const navigate = useNavigate();

  if (!course) {
    return <Typography variant="h4" color="error" align="center">Course Not Found</Typography>;
  }

  return (

    <Box className="course-container">
      <Button
        variant="outlined"
        onClick={() => navigate(-1)}
        sx={{
          mb: 2,
          fontWeight: "bold",
          color: "#FFD700", // Purple text
          borderColor: "#FFD700", // Purple border
          textTransform: "none",
          borderRadius: "8px",
          padding: "8px 16px",
          "&:hover": {
            backgroundColor: "#FFD700", // Purple background on hover
            color: "#6A1B9A", // White text on hover
            borderColor: "#6A1B9A",
          }
        }}
      >
        ← Back
      </Button>
      {/* Header Section */}

      <Box className="course-header">
        <Box className="course-image-container">
          <img src={course.image} alt={course.title} className="course-image" />
        </Box>
        <Box className="course-info">
          <Typography variant="h3" className="course-title">{course.title}</Typography>
          <Typography variant="body1" className="course-overview">{course.overview}</Typography>
          <Box className="buttons">
            {/* <Button variant="contained" className="btn-apply">Apply Now</Button> */}
            <Button variant="contained" className="btn-request" onClick={handleRegisterClick}>Request Callback</Button>
          </Box>
        </Box>
      </Box>

      {/* Course Details Section */}
      <Box className="course-details">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className="info-label">Duration</Typography>
            <Typography variant="body1" className="info-text">{course.duration}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className="info-label">Mode</Typography>
            <Typography variant="body1" className="info-text">{course.mode}</Typography>
          </Grid>
        </Grid>

        <Divider className="divider" />


        {/* Course Overview */}
        {/* <Box className="section-header">
          <Typography variant="h5" className="section-title">Course Overview</Typography>
        </Box>
        <Typography variant="body1" className="course-description">{course.overview}</Typography> */}

        {/* Key Topics - Accordion */}
        <Box className="section-header">
          <Typography variant="h5" className="section-title">Key Topics</Typography>
        </Box>
        {course.keyTopics.map((topic, index) => (
          <Accordion sx={{
            borderRadius: '10px',
            overflow: 'hidden',
          }} key={index} className="accordion">
            <AccordionSummary expandIcon={<ExpandMore />} className="accordion-summary">
              <Typography variant="h6">{topic.module}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <Typography variant="body2">Detailed content about {topic.module} will be covered in this section.</Typography> */}
              <ReactMarkdown>{topic.details}</ReactMarkdown>
            </AccordionDetails>
          </Accordion>
        ))}


        {/* Skills Covered */}

        {course.skillCovered && (
          <>
            <Box className="section-header">
              <Typography variant="h5" className="section-title">Skills Covered</Typography>
            </Box>
            <ul className="skills-list">
              {course.skillsCovered.map((skill, index) => (
                <li key={index} className="skill-item"><ReactMarkdown>{skill}</ReactMarkdown></li>
              ))}
            </ul>
          </>
        )}



        {/* Who is this course for? */}
        {course.targetAudience && (<>
          <Typography variant="h5" className="section-title"> Who is this course for?</Typography>
          <Typography variant="body1" className="target-audience">{course.targetAudience}</Typography>
        </>)}


        {/* Certification */}
        {course.certification && (
          <>
            <Typography variant="h5" className="section-title">🏆 Certification</Typography>
            <Box className="certification-box">
              <img src={course.certification.logo} alt={course.certification.title} className="certification-logo" />
              <Typography variant="h6">{course.certification.title}</Typography>
            </Box>
          </>
        )}

      </Box>
    </Box>
  );
};

export default CourseDetails;
