import { Link, Element, scroller } from "react-scroll";
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { Box } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { Fab, useScrollTrigger, Zoom, Tooltip, Tabs, Tab } from "@mui/material";
import { Modal, Backdrop, Fade, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function NavigationBar({ isSticky }) {
  const [activeTab, setActiveTab] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    handleNavigation(newValue);
  };

  const handleMobileMenuClick = (index) => {
    setActiveTab(index);
    setMobileMenuOpen(false);
    handleNavigation(index);
  };

  const handleNavigation = (value) => {
    if (value === 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const sections = ['timeline', 'why-choose-us', 'placement'];
      scroller.scrollTo(sections[value - 1], {
        smooth: true,
        duration: 500,
      });
    }
  };

  const navItems = ["Home", "Courses", "Why Choose Us", "Placements"];

  return (
    <>
      <AppBar
        position={isSticky ? "fixed" : "absolute"}
        sx={{
          backgroundColor: isSticky ? 'rgba(45, 27, 77, 0.95)' : 'transparent',
          boxShadow: isSticky ? '0 2px 10px rgba(0,0,0,0.25)' : 'none',
          transition: 'all 0.3s ease',
          backdropFilter: isSticky ? 'blur(10px)' : 'none',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            padding: {
              xs: '0.5rem',
              sm: '0.5rem 1rem',
              md: '0.5rem 2rem'
            },
            height: {
              xs: '60px',
              sm: '70px',
              md: '80px',
              lg: '110px'
            },
            position: 'relative',
            minHeight: {
              xs: '60px',
              sm: '70px',
              md: '80px',
              lg: '110px'
            }
          }}
        >
          {/* Logo container */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              left: {
                xs: '0.5rem',
                sm: '1rem',
                md: '2rem'
              },
              height: '100%',
              zIndex: 1,
            }}
          >
            <img
              src="/lilaq-logo-new.png"
              alt="LilaQ.Ai Academy Logo"
              style={{
                height: 'auto',
                maxHeight: '100%',
                width: 'auto',
                transition: 'all 0.3s ease',
                filter: isSticky ? 'brightness(1.2)' : 'none',
              }}
              sx={{
                height: {
                  xs: '50px',
                  sm: '60px',
                  md: '80px',
                  lg: '100px'
                }
              }}
            />
          </Box>

          {/* Spacer with responsive width */}
          <Box sx={{
            width: {
              xs: '100px',
              sm: '120px',
              md: '150px',
              lg: '180px'
            }
          }} />

          {/* Desktop Navigation Tabs */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="inherit"
            sx={{
              '& .MuiTab-root': {
                color: '#ffffff',
                fontWeight: 500,
                opacity: 0.7,
                transition: 'all 0.3s ease',
                padding: {
                  md: '12px 16px',
                  lg: '12px 24px'
                },
                fontSize: {
                  md: '14px',
                  lg: '16px'
                },
                '&.Mui-selected': {
                  color: '#d4af37',
                  opacity: 1,
                  fontWeight: 700,
                },
                '&:hover': {
                  color: '#d4af37',
                  opacity: 0.9,
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#b59de6',
                height: 3,
              },
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {navItems.map((item, index) => (
              <Tab key={item} label={item} />
            ))}
          </Tabs>

          {/* Certification Logos */}
          <Box sx={{
            display: { xs: 'none', lg: 'flex' },
            gap: '1rem',
            alignItems: 'center',
          }}>
            {/* Add certification logos here if needed */}
          </Box>

          {/* Mobile Menu Toggle */}
          <IconButton
            onClick={() => setMobileMenuOpen(true)}
            sx={{
              display: { xs: 'flex', md: 'none' },
              color: '#ffffff',
              marginLeft: 'auto'
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: {
              xs: '100%',
              sm: '300px'
            },
            backgroundColor: 'rgba(45, 27, 77, 0.98)',
            backdropFilter: 'blur(10px)',
          }
        }}
      >
        <List sx={{ pt: 4 }}>
          {navItems.map((item, index) => (
            <ListItem
              key={item}
              onClick={() => handleMobileMenuClick(index)}
              sx={{
                color: '#ffffff',
                '&:hover': {
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                }
              }}
            >
              <ListItemText
                primary={item}
                sx={{
                  textAlign: 'center',
                  '& .MuiTypography-root': {
                    fontSize: '1.2rem',
                    fontWeight: activeTab === index ? 700 : 500,
                    color: activeTab === index ? '#d4af37' : '#ffffff',
                  }
                }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}

export default NavigationBar;