import React from "react";
import { useNavigate } from "react-router-dom";
import "./CoursesPage.css";
import { Box, Typography, Grid, Card, CardContent, Button, CardMedia } from "@mui/material";

const courses = [
  { 
    id: "full-stack-development", 
    title: "Full-Stack Development", 
    description: "Master frontend & backend development with hands-on projects. Learn MERN stack, RESTful APIs, and responsive web design to build powerful, scalable applications.",
    image: "/full-stack.png" 
  },
  { 
    id: "ai-machine-learning", 
    title: "AI / Machine Learning And Power BI", 
    description: "Learn deep learning, NLP, and AI model building from industry experts. Master neural networks, natural language processing, and machine learning frameworks like TensorFlow.",
    image: "/ai-ml.webp" 
  },
  { 
    id: "genai-prompt-engg", 
    title: "Generative AI and Prompt Engineering", 
    description: "Learn advanced techniques for working with Generative AI, prompt engineering, and APIs like OpenAI.",
    image: "/genai.webp" 
  },
  { 
    id: "big-data-analytics", 
    title: "Big Data Analytics", 
    description: "Harness the power of big data tools like Hadoop, Spark, and Tableau for data-driven decision-making.",
    image: "/bigdata.jpg" 
  },
  { 
    id: "corporate-trainings", 
    title: "Corporate Batch Trainings", 
    description: "Focuses on equipping employees with cutting-edge tools, frameworks, and methodologies aligned with industry trends and organizational needs. Ideal for companies seeking team-wide training in a specific skill set.",
    image: "/corp-train.avif",
    group: true
  },
  { 
    id: "safe-agile-training", 
    title: "SAFe® Agile Training", 
    description: "Learn Scaled Agile Framework (SAFe®), empowering professionals to implement Agile practices across the enterprise efficiently and effectively. LilaQ AI Academy’s SAFe® Agile Training enables organizations need to scale agility beyond teams to stay competitive and deliver value faster.",
    image: "safe.png",
    group: true
  },
  { 
    id: "onsite-campus-training", 
    title: "Placement Assistance Support Program", 
    description: "These programs are designed to address specific needs of educational institutions and organizations by delivering high-quality training in technical skills, leadership development, and career-oriented courses.",
    image: "campus.webp",
    group: true 
  },
  // { 
  //   id: "cyber-security-training", 
  //   title: "Cyber Security Trainings", 
  //   description: "Understand security protocols, ethical hacking, and network security.",
  //   image: "/cybersec.jpg" 
  // },
  { 
    id: "cloud-computing", 
    title: "Mastering Cloud Resources ( AWS & Azure )", 
    description: "Learn cloud infrastructure, DevOps practices, and CI/CD pipeline design for scalable solutions.",
    image: "/cloud.jpg" 
  },
  // { 
  //   id: "software-testing", 
  //   title: "Software Testing Training - Manual & Automation", 
  //   description: "Gain expertise in software testing with hands-on experience in both manual and automated techniques to ensure software quality and reliability.",
  //   image: "/soft-test.jpg" 
  // },
  { 
    id: "ui-ux-design", 
    title: "Mastering UI/UX Design", 
    description: "Design intuitive user interfaces and experiences with tools like Figma, Adobe XD, and Sketch.",
    image: "/ui-ux.jpeg" 
  },
  { 
    id: "flutter-dev", 
    title: "Cross Platform App Development using Flutter", 
    description: "Build fast and scalable cross-platform applications with Dart and Flutter.",
    image: "/flutter.jpg" 
  },
  { 
    id: "dev-ops", 
    title: "Mastering DevOps & Cloud Automation", 
    description: "Build CI/CD pipelines, cloud infrastructure automations, containerized deployments, monitoring dashboards, and learn security best practices",
    image: "/dev-ops.png" 
  }
];

const CoursesPage = () => {
  const navigate = useNavigate();

  return (
    <Box className="courses-page">
      <Box className="video-container">
        <video className="background-video" autoPlay loop muted playsInline webkit-playsinline>
          <source src="/training.mp4" type="video/mp4" />
        </video>
        <Box className="overlay"></Box>
      </Box>

      <Typography variant="h3" className="page-title">
        Explore Our Courses
      </Typography>

      <Grid container spacing={4} className="courses-container">
        {courses.map((course) => (
          <Grid item xs={12} sm={6} md={3} key={course.id}>
            <Card className="course-card">
              <CardMedia
                component="img"
                height="180"
                image={course.image}
                alt={course.title}
                className="course-image"
              />
              <CardContent>
                <Typography variant="h5" className="course-title">
                  {course.title}
                </Typography>
                <Typography variant="body2" className="course-description">
                  {course.description}
                </Typography>
                <Button
                  variant="contained"
                  className="btn-glass"
                  onClick={() => navigate(`/courses/${course.id}`)}
                >
                  Learn More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoursesPage;
