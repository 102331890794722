import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { handleRegisterClick } from "./Common";
import "./HeroSlider.css";

const slides = [
    {
        title: "Welcome to LilaQ.Ai Academy",
        description: "Your journey to becoming a tech leader starts here. Learn practical, real-world skills tailored for success.",
        image: "/academy-image-1.jpg",
    },
    {
        title: "Master AI & Machine Learning",
        description: "Become an expert in AI with industry-relevant courses and hands-on projects.",
        image: "/academy-image-2.jpg",
    },
    {
        title: "Full-Stack Development",
        description: "Learn frontend and backend development with real-world applications.",
        image: "/academy-image-3.jpg",
    },
    {
        title: "Cloud Computing & DevOps",
        description: "Master AWS, Azure, and DevOps tools to become a cloud expert. Learn infrastructure automation and deployment strategies used by top enterprises.",
        image: "/academy-image-4.webp",
    },
    {
        title: "100% Placement Assistance",
        description: "Our dedicated placement team ensures you get job-ready with resume building, mock interviews, and direct connections with hiring companies.",
        image: "/academy-image-5.jpg",
    },
];

const HeroSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 5050);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="hero-slider">
            {/* <div className='hero-slide'>
                <img
                    src="/lilaq-logo-new.png"
                    alt="Logo"
                    className="hero-logo"
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                />
            </div> */}
            <AnimatePresence mode="wait">

                <motion.div
                    key={currentSlide}
                    className="hero-slide"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 50 }}
                    transition={{ duration: 0.7 }}
                >
                    <div className="hero-content">
                        {/* <motion.img
                            src="/lilaq-logo-bot.png"
                            alt="Logo"
                            className="hero-logo"
                            initial={{ y: -50, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                        /> */}
                        <h1 className="hero-title">{slides[currentSlide].title}</h1>
                        <p className="hero-description">{slides[currentSlide].description}</p>
                        <button className="cta-btn primary" onClick={() => {
                            const timelineSection = document.getElementById("timeline");
                            if (timelineSection) {
                                timelineSection.scrollIntoView({ behavior: "smooth" });
                            }
                        }}>
                            Explore Courses
                        </button>
                        <button className="cta-btn secondary learn-more-btn" onClick={handleRegisterClick}>
                            Learn More
                        </button>
                    </div>
                    <motion.img
                        src={slides[currentSlide].image}
                        alt="Slide Image"
                        className="hero-image"
                    />
                </motion.div>
            </AnimatePresence>

            {/* Navigation Dots */}
            <div className="hero-nav">
                {slides.map((_, index) => (
                    <div
                        key={index}
                        className={`hero-dot ${index === currentSlide ? "active" : ""}`}
                        onClick={() => setCurrentSlide(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default HeroSlider;
